#message {
    resize: vertical;
}

.response-message {
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
}
  

@media screen and (max-width: 1060px) {
}

@media screen and (max-width: 700px) {
}

@media screen and (max-width: 500px) {
}