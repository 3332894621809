.article {
    height: 100%;
    background-repeat: no-repeat;
    background-position: top ;
    background-size: cover;
    display: grid;
    place-items: center;
    box-shadow: inset 0 0 0 2000px rgba(30, 28, 29, 0.3);
}

.header {
    color: white;
    text-align: center;
    font-size: 3rem;
    line-height: 4rem;
}


@media screen and (max-width: 490px) { 
    .header {
        color: white;
        text-align: center;
        font-size: 2rem;
        line-height: 3rem;
    }
}