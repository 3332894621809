#countryFlag,
PhoneInputCountry {
  outline: none;
  position: relative;
  width: 38px !important;
  height: 100% !important;
  padding: 0 8 0 8px;
  border-radius: 5px 0 0 5px;
  background-color: #333333;
}

.countrySelector input,
.css-qbdosj-Input input {
  box-shadow: none;
}

.countryCodeSelector,
.industrySelector {
  width: 200px;
  border: none;
}

#countryCodeRead {
  width: 50px;
  padding-right: 0px;
}

.success-buttton {
  align-content: center;
  text-align: center;
}

.instructions {
  font-size: 0.75rem;
  border-radius: 0.5rem;
  background: #333333;
  color: #fff;
  padding: 0.25rem;
  position: relative;
  bottom: -10px;
}

.instructions > svg {
  margin-right: 0.25rem;
}

.offscreen {
  position: absolute;
  left: -9999px;
}
