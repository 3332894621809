.map-container {
  height: 500px;
  width: 100%;
}

.sidebar {
  background-color: var(--intu-darkGreen);
  color: var(--intu-darkGrey);
  padding: 6px 12px;
  font-family: monospace;
  z-index: 1;
  position: absolute;
  margin: 12px 12px;
  border-radius: 4px;
  margin-top: 6rem;
}

.mapboxgl-ctrl-logo {
  display: none !important;
}

.mapboxgl-popup {
  max-width: 200px;
}

.mapboxgl-popup-content {
  background-color: var(--intu-darkGreen);
  text-align: center;
  font-family: 'Open Sans', sans-serif;
}
