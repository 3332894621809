/* Parallax Zoom Out Component */
.parallaxZoomOutContainer {
  position: relative;
  height: calc(2.5 * 50vh);
  z-index: 1;
  overflow: visible;
  padding-bottom: 60vh;

  .darkOverlay {
    position: absolute;
    background-color: #333333;
    height: 100%;
    width: 100%;
    z-index: 5;
  }

  .whiteOverlay {
    position: absolute;
    background-color: #ffffff;
    height: 100%;
    width: 100%;
    z-index: 5;
  }

  .imageContainer {
    background-size: cover;
    background-position: center;
    width: 25%;
    overflow: visible;
    left: 38%;
  }

  .textContainer {
    position: sticky;
    display: flex;
    top: 50%;
    left: 10%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 80%;
    margin: auto;
    z-index: 10;
    text-align: center;
  }

  .contentContainer {
    position: relative;
    /* top: 100%; */
  }
}

/* Parallax Zoom In Out Component */
.parallaxZoomInOut {
  margin-bottom: -25vh;

  .headerContainer {
    display: flex;
    position: absolute;
    text-align: center;
    background-size: cover;
    background-position: center;
    justify-content: center;
    align-items: center;
    width: 75%;
  }

  .parallaxOverlayContainer {
    position: relative;
    height: calc(1.25 * 100vh);
    z-index: 1;

    .imageContainer {
      background-size: cover;
      height: 120vh;
      width: 100%;
      position: absolute;

      .darkOverlay {
        position: absolute;
        inset: 0;
        opacity: 0.8;
        background-color: #333333;
      }
    }

    .textOverlayContainer {
      position: absolute;
      display: flex;
      top: 0;
      left: 10%;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 80%;
      height: 55%;
      margin: auto;
      z-index: 2;
    }

    .contentContainer {
      display: flex;
      position: absolute;
      flex-direction: column;
      width: 100%;
      align-items: center;
      text-align: center;
      z-index: 0;
    }
  }

  .parallaxChildrenContainer {
    position: relative;
    display: flex;
    top: -50vh;
  }
}

/* Scrolling Cards Component */
.scrollingCardsContainer {
  position: relative;

  .scrollingCardsSubContainer {
    width: 100%;

    .cardContainer {
      display: flex;
      align-items: center;
      justify-content: center;
      position: sticky;
      width: 80%;
      margin-bottom: 15%;

      .darkOverlay {
        position: absolute;
        inset: 0;
        background-color: #333333;
        border-radius: 50px;
      }
    }

    .headerContainer {
      display: flex;
      width: 100%;
      text-align: center;
    }

    .footerContainer {
      margin-top: 5vh;
      padding: 0 32px;
      text-align: center;
      display: flex;
      justify-content: center;
    }
  }
}

/* Text Highlight */
.TextHighlightContainer {
  display: inline-flex;
  flex-wrap: wrap;

  .TextHighlightWord {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    margin-right: 1vw;
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5;
    /* margin-top: 1vh; */

    .TextHighlightShadow {
      position: absolute;
      opacity: 0.1;
    }
  }
}

.image-container {
  cursor: pointer;
}

/* Image fullscreen */
.fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 9999;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
}

.fullscreen img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

/* Down Arrows */
.arrows {
  width: 60px;
  height: 72px;
  position: absolute;
  left: 50%;
  margin-left: -30px;
  bottom: 20px;
}

.arrows path {
  stroke: #a8c957;
  fill: transparent;
  stroke-width: 1px;
  animation: arrow 2s infinite;
  -webkit-animation: arrow 2s infinite;
}

@keyframes arrow {
  0% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
  80% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

@-webkit-keyframes arrow /*Safari and Chrome*/ {
  0% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
  80% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

.arrows path.a1 {
  animation-delay: -1s;
  -webkit-animation-delay: -1s; /* Safari 和 Chrome */
}

.arrows path.a2 {
  animation-delay: -0.5s;
  -webkit-animation-delay: -0.5s; /* Safari 和 Chrome */
}

.arrows path.a3 {
  animation-delay: 0s;
  -webkit-animation-delay: 0s; /* Safari 和 Chrome */
}

/* Draggable Carousel */
.slider-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* margin: 25px 0; */

  .slider {
    position: relative;
    width: 100vw;
    overflow: hidden;

    .content {
      position: absolute;
      height: 100%;
      width: 100%;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      will-change: transform, opacity;

      &:hover {
        cursor: grab;
      }

      &:active {
        cursor: grabbing;
      }
    }
  }

  .buttons {
    margin-top: 12px;

    button {
      outline: none;
      border: none;
      user-select: none;
      padding: 10px 12px;
      transform: skewY(-5deg) rotate(5deg);
      background-color: grey;
      font-family: inherit;
      font-size: inherit;
      color: white;
      transition: 0.07s ease-out transform;

      &:first-of-type {
        margin-right: 15px;
      }

      &:hover {
        cursor: pointer;
      }

      &:active {
        transform: scale(0.85) skewY(-5deg) rotate(5deg);
      }
    }
  }
}

.thumbnails {
  display: flex;
  justify-content: center;

  .thumbnail-container {
    position: relative;
    height: 120px;
    width: 90px;

    &:hover {
      cursor: pointer;
    }

    &:not(:last-of-type) {
      margin-right: 5px;
    }

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      object-position: center;
    }

    .active-indicator {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      pointer-events: none;
      transform: scaleX(0);
      transform-origin: left;
      background-color: grey;
      transition: 1s cubic-bezier(0.56, 0.03, 0.12, 1.04) transform;

      &.active {
        transform: scaleX(1);
      }
    }
  }
}

.carousel-indicator {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  gap: 20px;

  .dot {
    background-color: #333333;
    width: 15px;
    height: 15px;
    border-radius: 50%;
  }
  .active {
    background-color: #a8c957;
  }
}
