.particles,
.heroContent {
    position: absolute;
    width: 100%;
    z-index: -1;
}

.heroText {
    position: absolute;
    width: 100%;
    z-index: -1;
}