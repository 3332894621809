@font-face {
  font-family: 'AvenirLight';
  src:
    local('AvenirLight'),
    url('./fonts/Avenir-Light.ttf') format('truetype');
  font-weight: 300;
}

@font-face {
  font-family: 'AvenirLightOblique';
  src:
    local('AvenirLightOblique'),
    url('./fonts/AvenirLTPro-LightOblique.ttf') format('truetype');
  font-weight: 300;
}

@font-face {
  font-family: 'UbuntuRegular';
  src:
    local('UbuntuRegular'),
    url('./fonts/UBUNTU-Regular.ttf') format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: 'UbuntuLight';
  src:
    local('UbuntuLight'),
    url('./fonts/UBUNTU-Light.ttf') format('truetype');
  font-weight: light;
}

:root {
  --font-family-heading: 'UbuntuRegular', sans-serif;
  --font-family-subheading: 'UbuntuLight', sans-serif;
  --font-family-content: 'AvenirLight', sans-serif;
  --font-family-button: 'AvenirLight', sans-serif;
  --gradient-text: -webkit-linear-gradient(
    left,
    #a8c957 1.84%,
    #ffffff 102.67%,
    transparent 100%
  );
  --gradient-bar: linear-gradient(103.22deg, #ae67fa -13.86%, #f49867 99.55%);

  --color-bg-dark-theme: #333333;
  --color-paper-dark-theme: white;

  --color-bg-light-theme: white;
  --color-paper-light-theme: white;

  /* Modal Baackgrounds */
  --color-modal-bg: rgba(51, 51, 51, 0.8);
  --color-modal-lg: rgba(51, 51, 51, 0.493);

  /* Cookie Consent Background */
  --cookie-consent-background: #333333af;
  --cookie-context-color: #ffffff;
  --cookie-consent-decline-btn: rgb(255, 0, 0);

  /* Phone */
  --PhoneInputCountryFlag-borderColor: none;
  --PhoneInput-color--focus: #a8c957;
  --PhoneInput-color-text: white;
  --PhoneInputCountryFlag-aspectRatio: 1.5;
  --PhoneInputCountryFlag-height: 1.5em;
  --PhoneInputCountryFlag-borderWidth: 0px;

  --color-body: #333333;
  --color-blog: #333333;
  --color-highlight-light: #a8c957;
  --color-highlight-dark: #333333;

  /* Color Cards */
  --color-card-background: #a9c957b1;
  --color-card-text: white;
  --color-card-background-hover: #a8c957;
  --color-card-text-hover: #333333;

  --color-nav-mobile-drawer: white;
  --color-nav-button: #333333;
  --color-nav-button-text: white;
  --color-nav-link-text: #333333;
  --color-nav-link-inactive: #a8c957;
  --color-nav-link-active: #a8c957;
  --color-burger-menu-bg: rgba(168, 201, 65, 0.7);
  --color-burger-menu-color: #333333;
  --color-burger-menu-color-active: white;

  --font-size-navbar-text: 1.1rem;
  --font-color-navbar-text: white;

  /* Hero Banner */
  --color-hero-overlay: #333333ae;

  /* Footer */
  --color-footer: #a8c957;
  --color-footer-bar: #3c3c3c;

  /* H1 */
  --font-size-header-1: '3rem';
  --font-color-header-1: #a8c957;

  /* H2 */
  --font-size-header-2: 3rem;

  /* H3 */
  --font-size-header-3: 1.75rem;

  /* H4 */
  --font-size-header-4: 1.5rem;

  /* H5 */
  --font-size-header-5: 1.25rem;

  /* H6 */
  --font-size-header-6: 1rem;

  /* P */
  --font-size-content: 1rem;

  --color-divider-drawer: #a8c957;
  --color-divider: #a8c957;
  --color-text-body: white;
  --color-text-light: white;
  --color-text-dark: #333333;

  /* ScrollBar Color */
  --color-scrollbar: rgb(168, 201, 87);

  /* Button & Input Box Styles */
  --text-size-button: 1.1rem;

  --color-button-light-text: #a8c957;
  --color-button-light-backround: #333333;
  --color-button-light-border: solid 1px #a8c957;
  --color-button-light-text-active: #333333;
  --color-button-light-backround-active: #a8c957;
  --color-button-light-border-active: solid 1px #333333;

  --color-button-warning-text: #d47c11;
  --color-button-warning-backround: 'none';
  --color-button-warning-border: solid 1px #d47c11;
  --color-button-warning-text-active: #d47c11b3;
  --color-button-warning-backround-active: 'none';
  --color-button-warning-border-active: solid 1px #d47c11b3;

  --color-button-dark-text: #333333;
  --color-button-dark-backround: #a8c957;
  --color-button-dark-text-active: #a8c957;
  --color-button-dark-backround-active: #333333;
  --color-button-dark-border-active: solid 1px #a8c957;

  --color-button-primary-text: #333333;
  --color-button-primary-border: none;
  --color-button-primary-border-color: none;
  --color-button-primary-outline: none;
  --color-button-primary-backround: #a8c957;
  --color-button-primary-text-active: white;
  --color-button-primary-backround-active: #333333;
  --color-button-primary-border-active: none;
  --color-button-primary-border-color-active: #a8c957;
  --color-button-primary-text-focus: #333333;
  --color-button-primary-border-focus: solid 1px;
  --color-button-primary-border-color-focus: #a8c957;
  --color-button-primary-backround-focus: #a8c957;
  --text-button-primary: 1.1rem;

  --color-button-secondary-text: #a8c957;
  --color-button-secondary-border: solid 1px;
  --color-button-secondary-border-color: #a8c957;
  --color-button-secondary-backround: none;
  --color-button-secondary-text-active: #333333;
  --color-button-secondary-border-active: solid 1px;
  --color-button-secondary-backround-active: #a8c957;
  --color-button-secondary-border-color-active: #333333;
  --text-button-secondary: 1.1rem;

  /* MUI Slider */
  --color-slider: #a8c957;

  /* MUI Autucomplete & Tag Styles */
  --color-autocomplete-input-text: #333333;
  --color-popup-indicator-selected-option: #a8c957;
  --color-tag-popup-indicator: #a8c957;
  --color-tag-popup-indicator-hover: white;
  --color-tag-background: #a8c957;
  --color-tag-text: #333333;
  --color-tag-icon-delete: #333333;
  --color-tag-icon-delete-hover: #ac0e26;

  --color-label-icon: rgb(163, 160, 160);
  --color-label-icon-active: #a8c957;

  --color-inputbox-border-active: rgb(168, 201, 87);
  --color-inputbox-color-success: #a8c957;
  --color-inputbox-border-error: rgb(255, 0, 0);

  --color-text-selection-text: #a8c957;
  --color-text-inputbox: #333333;
  --background-input-box: rgba(255, 255, 255, 0.21);
  --background-input-box-success: rgba(167, 220, 31, 0.743);

  --color-text-error-message: rgb(255, 0, 0);

  /* Status Messages  */
  --color-html-status-message: #a8c957;
  --color-html-status-message: #333333;

  --color-subtext: #a8c957;

  /* MUI Data Grid */
  --grid-header-background: #a8c957;
  --grid-header-text-color: #333333;

  /*-------------- NEW VARIABLES ------------- */

  /* Content */
  --intu-darkGreen: #a8c957;
  --intu-darkGrey: #333333;
  --intu-lightGrey: #e5e8eb;

  /* Navbar */
  --color-navbar-dark-mode: #2c2c2e76;
  --color-navbar-light-mode: #333333;

  /* Drawer */
  --color-list-item-light-theme: #a8c957;
  --color-list-item-dark-mode: #333333;

  /* Nav Buttons */
  --color-primary-button: #a8c957;
  --border-primary-button: 'transparent';

  --color-secondary-button: #333333;
  --border-primary-button: #a8c957;

  /* MUI Text & Input */
  --color-inputbox-label-light-mode: #7c8082;
  --color-inputbox-label-dark-mode: #a8c957;
  --color-inputbox-label-active: #a8c957;
  --color-inputbox-label-disabled-dark-mode: #7c8082;

  --color-inputbox-content-dark-mode: #ededed;
  --color-inputbox-content-light-mode: #333333;

  --color-inputbox-content-disabled-dark-mode: #7c8082;
  --color-inputbox-content-disabled-light-mode: #333333;

  --color-inputbox-border-dark-mode: #7c8082;
  --color-inputbox-border-light-mode: #a8c957;
  --color-inputbox-border-hover-dark-mode: #7c8082a9;
  --color-inputbox-border-hover-light-mode: #a9c957ab;
  --color-inputbox-border-disabled-dark-mode: #7c8082a9;
  --color-inputbox-border-disabled-light-mode: #7c8082a9;

  --color-inputbox-border-active-dark-mode: rgb(168, 201, 87);
  --color-inputbox-border-active-light-mode: rgb(168, 201, 87);

  --color-text-body-dark-mode: #ededed;
  --color-text-body-light-mode: #333333;

  /* autocomplete Options Drop Down */
  --color-option-background: rgba(169, 201, 87, 0.745);

  /* Tags */
  --color-tags-secondary-dark-mode: #a8c957;
  --color-tags-primary-dark-mode: #333333;

  --color-tags-secondary-light-mode: #333333;
  --color-tags-primary-light-mode: #a8c957;

  /* Mui DataGrid */
  --color-grid-border-light-mode: rgba(224, 224, 224, 1);
}
