.intu__footer {
  font-family: var(--font-family-content);
  font-size: var(--font-size-content);
  padding-top: 2rem;
  background-color: var(--color-footer);
  bottom: 0;
  margin-top: auto;
  position: sticky;
  left: 0;
  width: 100%;
  flex-shrink: 0;
}

.intu__footer-column {
  flex: 1;
  align-content: center;
  text-align: center;
  padding: 10px 0;
  width: 33.33%;
}

.intu__footer-bar {
  width: 100%;
  text-align: center;
  background-color: var(--color-footer-bar);
  padding: 10px;
}

.footer .social {
  display: flex;
  justify-content: flex-end;
  flex: 1;
}

.footer-link:hover {
  color: var(--color-text-light) !important;
}

.social {
  color: var(--color-nav-button);
  /* font-size: 30px; */
}

.social :hover {
  color: var(--color-nav-bar);
  transition:
    color 0.15s ease-in-out,
    background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
}

.item {
  text-align: center;
  padding: 10px;
  color: white;
}

@media screen and (max-width: 1050px) {
  .intu__footer {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
