.typewrite {
  color: white;
  text-decoration: none;
  font-size: 3em;
}

.intu__section-image {
  display: flex;
  justify-content: center;
  align-items: center;
  vertical-align: bottom;
  align-content: center;
  padding-bottom: 50px;
}

.intu__section-image img {
  max-width: 400px;
}

.section__content_header {
  align-content: center;
}

.intu__cta-button-center {
  padding: 50px;
}
