html {
  position: relative;
  min-height: 100%;
}

html,
body {
  margin: 0;
  padding: 0;
  font-family: var(--font-family-content);
  background-color: var(--color-bg);
}

* {
  box-sizing: border-box;
  scroll-behavior: smooth;
}

.hero-container {
  position: absolute;
  top: 0;
  color: #f1f1f1;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  object-fit: cover;
}

.hero-video {
  position: absolute;
  top: 0;
  color: #f1f1f1;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  object-fit: cover;
}

.content-left,
.header-left {
  text-align: left;
}

.content-centered,
.header-centered {
  align-content: center;
  text-align: center;
}

.padding_after {
  padding-bottom: 2rem;
}

.errmsg {
  background-color: lightpink;
  color: firebrick;
  font-weight: bold;
  padding: 0.5rem;
  margin-bottom: 0.5rem;
}

.hero-video {
  position: absolute;
  top: 0;
  color: #f1f1f1;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  object-fit: cover;
}

.offscreen {
  position: absolute;
  left: -9999px;
}

.form-light .MuiInputBase-input {
  color: var(--color-text-inputbox) !important;
}

/* ICONS */
.intu__react-icons {
  color: white;
  font-size: 2em;
}

/* HEADER */
.intu__header {
  display: flex;
}

.intu__header-content {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  margin-right: 5rem;
}

.intu__header-content__input {
  width: 100%;
  margin: 2rem 0 1rem;
  display: flex;
}

.intu__header-content__input input {
  flex: 2;
  width: 100%;
  min-height: 50px;
  font-family: var(--font-family-content);
  font-size: 20px;
  line-height: 27px;
  background: var(--background-input-box);
  border: none;
  padding: 0 1rem;
  outline: none;
  color: var(--color-inputbox);
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.intu__header-content__input button {
  flex: 0.6;
  width: 100%;
  min-height: 50px;
  font-style: bold;
  font-size: 18px;
  color: var(-light);
  background: var(--color-button);
  font-family: var(--font-family-button);
  font-weight: 500;
  line-height: 25px;
  cursor: pointer;
  outline: none;
  padding: 0 1rem;

  border: none;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.intu__header-image {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.intu__header-image img {
  width: 100%;
  padding-right: 0px;
}

@media screen and (max-width: 1050px) {
  .intu__header {
    flex-direction: column;
  }

  .intu__header-content {
    margin: 0 0 3rem;
  }
}

@media screen and (max-width: 650px) {
  .intu__header h1 {
    font-size: 48px;
    line-height: 60px;
  }

  .intu__header-content__input input,
  .intu__header-content__input button {
    font-size: 16px;
    line-height: 24px;
  }
}

@media screen and (max-width: 490px) {
  .intu__header h1 {
    font-size: 36px;
    line-height: 48px;
  }
  .intu__header p {
    font-size: 1rem;
    line-height: 1.2;
  }

  .intu__header-content__input input,
  .intu__header-content__input button {
    font-size: 12px;
    line-height: 16px;
  }
}

/* CONTENT */
.intu__section {
  min-height: calc(100vh - 270px);
}

.intu__page {
  padding-top: 2rem;
}

/* COMPONENTS */

.intu__component h1,
.intu__component h2,
.intu__component h3 {
  text-align: center;
}

.intu__component p {
  text-align: left;
}

.intu__section-new {
  padding: 50px;
}

.intu__dashboard_page {
  margin-top: 50px;
}

.content {
  min-height: 100vh;
  margin-bottom: -50px;
}

.font-face-gm {
  font-family: 'AvenirLight';
  color: white;
}

a {
  color: unset;
  text-decoration: none;
}

a:hover {
  color: var(--color-nav-link-active) !important;
}

.link {
  color: var(--color-nav-link-inactive) !important;
}

.link:hover {
  color: var(--color-nav-link-active) !important;
}

/* Form Styling */

form {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
}

.field-group {
  display: flex;
  flex-direction: row;
  position: relative;
  flex-wrap: nowrap;
  width: 100%;
  flex-direction: row;
}

.countryCode-select {
  width: 100px;
}

form label {
  margin-bottom: 5px;
}

form input {
  margin-bottom: 20px;
}

input:focus {
  box-shadow: rgba(168, 201, 87, 24%);
  outline: none;
}

input:-internal-autofill-selected {
  background-color: var(--color-burger-menu-bg);
}

.form-input-field {
  word-wrap: normal;
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  appearance: none;
  border-radius: 0.375rem;
  transition:
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}

.form-input-field:focus {
  border-color: var(--color-highlight);
  color: var(--color-text-inputbox);
}

.intu__input_error {
  background-color: #fe939dc7 !important;
}

.intu__error-text {
  color: #fe939dc7;
}

.intu__input_complete {
  background-color: var(--background-input-box-success) !important;
  color: white;
}

.intu__error_message {
  color: var(--color-text-error-message);
}

.intu__divider {
  border-bottom: 0.1rem solid var(--color-divider);
}

.gradient__bg_top_left {
  /* ff 3.6+ */
  background: -moz-linear-gradient(
    136deg,
    rgba(255, 255, 255, 0.25) 0%,
    rgba(0, 188, 212, 0) 23%,
    rgba(238, 130, 238, 0) 100%
  );

  /* safari 5.1+,chrome 10+ */
  background: -webkit-linear-gradient(
    136deg,
    rgba(255, 255, 255, 0.25) 0%,
    rgba(0, 188, 212, 0) 23%,
    rgba(238, 130, 238, 0) 100%
  );

  /* opera 11.10+ */
  background: -o-linear-gradient(
    136deg,
    rgba(255, 255, 255, 0.25) 0%,
    rgba(0, 188, 212, 0) 23%,
    rgba(238, 130, 238, 0) 100%
  );

  /* ie 6-9 */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#FFFFFF', endColorstr='#EE82EE', GradientType=1 );

  /* ie 10+ */
  background: -ms-linear-gradient(
    136deg,
    rgba(255, 255, 255, 0.25) 0%,
    rgba(0, 188, 212, 0) 23%,
    rgba(238, 130, 238, 0) 100%
  );

  /* global 94%+ browsers support */
  background: linear-gradient(
    136deg,
    rgba(255, 255, 255, 0.25) 0%,
    rgba(0, 188, 212, 0) 23%,
    rgba(238, 130, 238, 0) 100%
  );
}

.gradient__bg_bottom_right {
  /* ff 3.6+ */
  background: -moz-linear-gradient(
    325deg,
    rgba(255, 255, 255, 0.25) 0%,
    rgba(0, 188, 212, 0) 23%,
    rgba(238, 130, 238, 0) 100%
  );

  /* safari 5.1+,chrome 10+ */
  background: -webkit-linear-gradient(
    325deg,
    rgba(255, 255, 255, 0.25) 0%,
    rgba(0, 188, 212, 0) 23%,
    rgba(238, 130, 238, 0) 100%
  );

  /* opera 11.10+ */
  background: -o-linear-gradient(
    325deg,
    rgba(255, 255, 255, 0.25) 0%,
    rgba(0, 188, 212, 0) 23%,
    rgba(238, 130, 238, 0) 100%
  );

  /* ie 6-9 */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#FFFFFF', endColorstr='#EE82EE', GradientType=1 );

  /* ie 10+ */
  background: -ms-linear-gradient(
    325deg,
    rgba(255, 255, 255, 0.25) 0%,
    rgba(0, 188, 212, 0) 23%,
    rgba(238, 130, 238, 0) 100%
  );

  /* global 94%+ browsers support */
  background: linear-gradient(
    325deg,
    rgba(255, 255, 255, 0.25) 0%,
    rgba(0, 188, 212, 0) 23%,
    rgba(238, 130, 238, 0) 100%
  );
}

.gradient__text {
  background: var(--gradient-text);
  background-clip: text;
  color: var(--gradient-text);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.intu__success_message_hero {
  color: var(--gradient-text);
  text-align: center;
}

.form-select-field p {
  padding-bottom: 'none';
  color: var(--PhoneInput-color-text);
}

li::marker,
.intu__form-bullets li::marker,
.public-DraftStyleDefault-ul li::marker {
  color: var(--intu-darkGreen);
}

/* Style the list item */
.intu__form-bullets li,
.public-DraftStyleDefault-ul li {
  display: list-item;
  margin-left: 2ch;
  padding-inline-start: 1ch;
}

.content-light {
  color: var(--color-text-light);
}

.section__column p {
  padding: 0 0 1rem 0;
}

.intu__loading-spinner {
  display: flex;
  flex-direction: column;
  align-content: center;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
}

.intu__section {
  display: flex;
  flex-direction: column;
}

.intu__section-content {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: row;
  margin-right: 5rem;
}

.section__content_header h1 {
  font-weight: 400;
  font-size: 50px;
  line-height: 57px;
}

.intu__page_content {
  /* color: var(--color-text-light); */
  width: 100%;
  margin: 0;
  /* margin: 0rem 0 1rem */
}

.intu__page_content ol,
.intu__page_content ul {
  margin: 0 0 0 2rem;
  padding-bottom: 2rem;
}

.intu__section-element {
  width: 100%;
  margin: 0rem 0 1rem 2rem;
}

.intu__section-element_start {
  margin-left: 0;
}

.intu__section-image {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  vertical-align: bottom;
}

.intu__section-image img {
  width: 100%;
  padding-right: 0px;
  max-width: 600px;
}

.intu__section-light {
  background-color: white;
}

.intu__section-dark {
  background-color: #333333;
}

.intu__section-medium {
  background-color: #ededed;
}

.intu__section-content h1 {
  font-family: var(--font-family-heading);
  font-style: normal;
  font-weight: 400;
  font-size: var(--font-size-header-1);
  line-height: calc(var(--font-size-header-1) + 1.5rem);
}

.intu__section-content p,
.intu__section-content ul,
.intu__section-content ol {
  text-align: left;
  font-family: var(--font-family-content);
  font-style: normal;
  font-weight: 400;
  font-size: var(--font-size-content);
  line-height: calc(var(--font-size-content) + 0.1rem);
}

.intu__section-content ul {
  padding-left: 2rem;
}

.green-text,
.highlight-text-light {
  color: var(--color-highlight-light) !important;
}

.grey-text,
.highlight-text-dark {
  color: var(--color-highlight-dark) !important;
}

.white-text {
  color: white !important;
}

.light-text {
  color: var(--color-text-light);
}

.form-note {
  font-family: var(--font-family-content);
  font-size: var(--font-size-content);
  font-style: italic;
  color: white;
  padding: 0;
  margin: 0;
}

.form-field-valid input {
  color: var(--color-inputbox-color-success) !important;
}

.dark-text {
  color: var(--color-text-dark);
}

.section__padding-content {
  padding-bottom: 0;
}

.section__margin {
  margin: 4rem 6rem;
}

.scale-up-center {
  -webkit-animation: scale-up-center 0.4s cubic-bezier(0.39, 0.575, 0.565, 1)
    both;
  animation: scale-up-center 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

.video-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}

.video-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}

.form-dark .Mui-disabled input {
  -webkit-text-fill-color: var(--color-inputbox-content-disabled-dark-mode);
}

.form-light,
.form-light .Mui-disabled input,
.form-light .form-select-field input {
  color: var(--color-text-inputbox);
}

.intu__form-content p {
  padding-bottom: 1rem;
}

.intu__form-bullets li::marker {
  color: var(--intu-darkGreen);
}

.MuiAlert-message a {
  color: var(--color-html-status-message);
}

.MuiAlert-message a:hover {
  color: var(--color-html-status-message);
}

@-webkit-keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@media screen and (max-width: 1050px) {
  .intu__section-content {
    flex-direction: column-reverse;
    flex-wrap: nowrap;
    width: 100%;
  }

  .intu__section-content h1 {
    margin: 0 0 3rem;
  }

  .intu__section-element {
    margin: 0;
  }
}

@media screen and (max-width: 700px) {
  .section__padding-header {
    padding: 4rem;
  }

  .section__margin {
    margin: 4rem;
  }

  .section__content_header h1 {
    font-size: 2.5rem;
    line-height: 3rem;
  }

  .intu__section-content h1 {
    font-size: calc(var(--font-size-header-1) - 0.2rem);
    line-height: calc(var(--font-size-header-1) - 0.2rem + 1.5rem);
  }

  .intu__section-content p,
  .intu__section-content ul {
    font-size: calc(var(--font-size-content) - 0.2rem);
    line-height: calc(var(--font-size-content) + 0.1rem);
  }
}

@media screen and (max-width: 550px) {
  .section__padding-header {
    padding: 4rem 2rem;
  }

  .section__padding-content {
    padding: 1rem 0rem 1rem 0rem;
  }

  .section__margin {
    margin: 4rem 2rem;
  }

  .section__content_header h1 {
    font-size: calc(var(--font-size-header-1) - 0.4rem);
    line-height: calc(var(--font-size-header-1) - 0.4rem + 1.5rem);
  }

  .intu__section-content h1 {
    font-size: calc(var(--font-size-header-1) - 0.4rem);
    line-height: calc(var(--font-size-header-1) - 0.2rem + 1.5rem);
  }

  .intu__section-content p,
  .intu__section-content ul {
    font-size: calc(var(--font-size-content) - 0.2rem);
    line-height: calc(var(--font-size-content) + 0.1rem);
  }
}
