.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  color: white;
  background-color: var(--color-modal-bg);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000;
}
.spinner {
  font-size: 2rem;
  animation: pulse 1.5s linear 0.8s infinite normal forwards;
}
@keyframes pulse {
  0% {
    color: hsla(0, 0%, 100%, 1);
  }
  50% {
    color: hsla(0, 0%, 100%, 0.1);
  }
  100% {
    color: hsla(0, 0%, 100%, 1);
  }
}
